<template>
  <div class="container">
    <div class="title">实名认证</div>
    <div
      class="content"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="form_box">
        <p
          class="auth_status_tip"
          v-if="authStatus == 1 "
        >* 认证中，暂不支持修改认证信息</p>
        <p
          class="auth_status_tip"
          v-if="authStatus == 2 "
        >* 已认证不支持修改认证信息</p>
        <p
          class="auth_status_tip"
          v-if="authStatus == 3 && authType == 2 "
        >* 认证失败，{{companyForm.verify_remark}}</p>
        <p
          class="auth_status_tip"
          v-if="authStatus == 3 && authType == 1 "
        >* 认证失败，{{personalForm.verify_remark}}</p>
        <el-form
          ref="form"
          label-width="150px"
        >
          <el-form-item label="认证类型">
            <div class="costom_form_item">
              <el-select
                v-model="authType"
                placeholder="请选择认证类型"
                :disabled="authStatus == 1 || authStatus==2"
                @change="changeAuthType"
              >
                <el-option
                  label="个人"
                  :value="1"
                ></el-option>
                <el-option
                  label="企业"
                  :value="2"
                ></el-option>
              </el-select>

              <div class="tips">
                <p v-if="authType ==1">*注：仅适用于个人</p>
                <p v-else>*注：适用于企业、党政及国家机关、事业单位、民办非企业单位、社会团体、个体工商户等</p>
              </div>
            </div>
          </el-form-item>
        </el-form>

        <!-- 个人认证 -->
        <el-form
          v-if="authType ==1"
          ref="personalForm"
          :model="personalForm"
          label-width="150px"
          :rules="personalRules"
        >
          <el-form-item
            label="真实姓名"
            prop="true_name"
          >
            <el-input
              v-model="personalForm.true_name"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="mobile"
          >
            <el-input
              v-model="personalForm.mobile"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="身份证号"
            prop="id_card_num"
          >
            <el-input
              v-model="personalForm.id_card_num"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="身份证人像面照片"
            prop="id_card_img_front"
          >
            <el-image
              v-if="authStatus == 1 || authStatus==2"
              style="height: 100px"
              :src="personalForm.id_card_img_front"
            ></el-image>
            <div
              class="costom_form_item"
              v-else
            >
              <el-upload
                action="/"
                list-type="picture-card"
                :http-request="uploadIdCardFront"
                :before-remove="clearIdCardFront"
                :limit="1"
                :file-list="idCardFrontImg"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="tips">

                <p>1.请上传清晰彩色完整的原件照片、身份证各项信息及头像清晰可见容易识别</p>
                <p>2.证件必须真实拍照，不能使用复印件</p>
              </div>
            </div>

          </el-form-item>
          <el-form-item
            label="身份证国徽面照片"
            prop="id_card_img_back"
          >
            <el-image
              v-if="authStatus == 1 || authStatus==2"
              style="height: 100px"
              :src="personalForm.id_card_img_back"
            ></el-image>
            <div
              class="costom_form_item"
              v-else
            >
              <el-upload
                action="/"
                list-type="picture-card"
                :http-request="uploadIdCardBack"
                :before-remove="clearIdCardBack"
                :limit="1"
                :file-list="idCardBackImg"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="tips">

                <p>1.请上传清晰彩色完整的原件照片、身份证各项信息及头像清晰可见容易识别</p>
                <p>2.证件必须真实拍照，不能使用复印件</p>
              </div>
            </div>
          </el-form-item>

        </el-form>

        <!-- 企业认证 -->
        <el-form
          v-else-if='authType == 2'
          ref="companyForm"
          :model="companyForm"
          label-width="150px"
          :rules="companyRules"
        >
          <el-form-item
            label="公司名称"
            prop="company_name"
          >
            <el-input
              v-model="companyForm.company_name"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="公司注册地"
            prop="company_region"
          >
            <!-- <el-input
              v-model="companyForm.company_region"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input> -->
            <el-cascader
              style="width:50%"
              v-model="companyForm.company_region"
              :options="cityData"
              :props="cityProps"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="company_address"
          >
            <el-input
              v-model="companyForm.company_address"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系人姓名"
            prop="connect_user_name"
          >
            <el-input
              v-model="companyForm.connect_user_name"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系人电话"
            prop="connect_user_mobile"
          >
            <el-input
              maxlength="11"
              v-model="companyForm.connect_user_mobile"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="营业执照副本照片"
            prop="license_img"
          >
            <el-image
              v-if="authStatus == 1 || authStatus==2"
              style="height: 100px"
              :src="companyForm.license_img"
            ></el-image>
            <div
              class="costom_form_item"
              v-else
            >
              <el-upload
                action="/"
                list-type="picture-card"
                :http-request="uploadLicense"
                :before-remove="clearLicense"
                :limit="1"
                :file-list="licenseImg"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="tips">

                <p>1.请上传清晰彩色完整的照片、营业执照各项信息清晰可见容易识别</p>
                <p>2.证件必须真实拍照，不能使用复印件</p>
              </div>
            </div>

          </el-form-item>
          <el-form-item
            label="社会统一编码"
            prop="license_num"
          >
            <el-input
              v-model="companyForm.license_num"
              :disabled="authStatus == 1 || authStatus==2"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="btn_box">
          <el-button
            type="primary"
            v-if="authStatus == 0 || authStatus==3"
            @click="clickSubmitBtn"
          >保 存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getAuthInfoApi, authPersonalApi, authCompanyApi } from '../../../api/user'
import { uploadFileApi } from '../../../api/common'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var pattern = /^(1[3|4|5|7|8|9][0-9])\d{8}$/
        // this.disabledBtn = !pattern.test(this.mobile)
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确手机号'))
        }
      }
    }
    var validateIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'))
      } else {
        var pattern = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        // this.disabledBtn = !pattern.test(this.mobile)
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确身份证号'))
        }
      }
    }
    return {
      cityData: [],
      cityProps: {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name'
      },
      loading: false,
      authStatus: 0, // 认证状态：0=未认证；1=认证中；2=已认证；3=认证失败
      authType: '', // 1=个人认证；2=公司认证 0=未认证
      idCardFrontImg: [],
      idCardBackImg: [],
      licenseImg: [],
      personalForm: {
        true_name: '',
        mobile: '',
        id_card_num: '',
        id_card_img_front: '',
        id_card_img_back: ''
      },
      companyForm: {
        company_name: '',
        company_region: '',
        company_address: '',
        connect_user_name: '',
        connect_user_mobile: '',
        license_img: '',
        license_num: ''
      },
      personalRules: {
        true_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        mobile: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        id_card_num: [
          { validator: validateIdCard, trigger: 'blur' }
        ],
        id_card_img_front: [
          { required: true, message: '请上传身份证人面像照片', trigger: 'blur' }
        ],
        id_card_img_back: [
          { required: true, message: '请上传身份证国徽面照片', trigger: 'blur' }
        ]
      },
      companyRules: {
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        company_region: [
          { required: true, message: '请输入企业注册地', trigger: 'blur' }
        ],
        company_address: [
          { required: true, message: '请输入企业详细地址', trigger: 'blur' }
        ],
        connect_user_name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        connect_user_mobile: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        license_img: [
          { required: true, message: '请上传营业执照照片', trigger: 'blur' }
        ],
        license_num: [
          { required: true, message: '请输入营业执照号', trigger: 'blur' }
        ]
      }

    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.getAuthInfo()
    this.getCity()
  },
  mounted () { },
  // 组件方法
  methods: {
    getCity () {
      axios.get('/jsonCity/city.json', {}).then((res) => {
        // console.log(res.data)
        this.cityData = res.data
      })
    },
    changeAuthType (val) {
      console.log(val)
      if (val === 1) {
        this.$refs.companyForm.clearValidate()
      } else {
        this.$refs.personalForm.clearValidate()
      }
    },
    async getAuthInfo () {
      this.loading = true
      const res = await getAuthInfoApi()
      this.loading = false
      console.log(res)
      if (res && res.code === 0) {
        if (res.data.auth_status) {
          this.authStatus = res.data.auth_status
          this.authType = res.data.auth_role
          if (this.authType === 1) {
            this.personalForm = res.data.auth_personal
            if (this.personalForm.id_card_img_front) {
              this.idCardFrontImg = [{
                name: '',
                url: this.personalForm.id_card_img_front
              }]
            }
            if (this.personalForm.id_card_img_back) {
              this.idCardBackImg = [{
                name: '',
                url: this.personalForm.id_card_img_back
              }]
            }
          } else if (this.authType === 2) {
            this.companyForm = res.data.auth_company
            this.companyForm.company_region = this.companyForm.company_region.split(',')
            if (this.companyForm.license_img) {
              this.licenseImg = [{
                name: '',
                url: this.companyForm.license_img
              }]
            }
          }
        } else {
          this.authType = 1
        }
      }
    },
    clickSubmitBtn () {
      if (this.authType === 1) {
        this.authPersonal()
      } else if (this.authType === 2) {
        this.authCompany()
      }
    },

    authCompany () {
      this.$refs.companyForm.validate(async (valid) => {
        if (!valid) return
        // console.log(...this.companyForm)
        const res = await authCompanyApi({ ...this.companyForm, company_region: this.companyForm.company_region.join(',') })
        // const res = await authCompanyApi(this.companyForm)
        if (res && res.code === 0) {
          this.$message.success('提交成功')
          this.getAuthInfo()
          this.$store.dispatch('getUserInfo', 1)
        }
      })
    },
    authPersonal () {
      this.$refs.personalForm.validate(async (valid) => {
        if (!valid) return
        const res = await authPersonalApi(this.personalForm)
        if (res && res.code === 0) {
          this.$message.success('提交成功')
          this.getAuthInfo()
          this.$store.dispatch('getUserInfo', 1)
        }
      })
    },

    async uploadIdCardFront (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      console.log(formData)
      const res = await uploadFileApi(formData)
      if (res && res.code === 0) {
        this.personalForm.id_card_img_front = res.data.url
        console.log(res.data.url)
      }
    },
    clearIdCardFront () {
      this.personalForm.id_card_img_front = ''
    },
    async uploadIdCardBack (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      console.log(formData)
      const res = await uploadFileApi(formData)
      if (res && res.code === 0) {
        this.personalForm.id_card_img_back = res.data.url
        // console.log(res.data.url)
      }
    },
    clearIdCardBack () {
      this.personalForm.id_card_img_back = ''
    },
    async uploadLicense (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      console.log(formData)
      const res = await uploadFileApi(formData)
      if (res && res.code === 0) {
        this.companyForm.license_img = res.data.url
        // console.log(res.data.url)
      }
    },
    clearLicense () {
      this.companyForm.license_img = ''
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    overflow-y: auto;
    .form_box {
      width: 800px;
      height: 100px;
      // background-color: pink;
      margin: 20px auto;

      .auth_status_tip {
        text-align: center;
        color: rgb(236, 77, 77);
        margin-bottom: 20px;
      }
      .btn_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .tips {
        width: 50%;
        font-size: 12px;
        color: #666;
        padding-left: 10px;
        box-sizing: border-box;
        p {
          line-height: normal !important;
        }
      }

      .el-select {
        width: 50%;
      }
      .el-input {
        width: 50%;
      }

      .el-form-item {
        // margin-bottom: 15px;
      }
      .costom_form_item {
        width: 100%;
        display: flex;
        align-items: center;
      }

      /deep/.el-input.is-disabled .el-input__inner {
        color: #333;
      }
    }
  }
}
</style>
